html {
  opacity: 1;
  transition: opacity 0.5s linear;
}
body {
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

:root {
  /*
  --text-color: #31343d;
  */
  --text-color: #373a4a;
}
