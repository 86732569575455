#header-content {
  display: flex;
  flex-wrap: wrap;
}
:root {
  --header-height: 70px;
}
#header-content > * {
  height: var(--header-height);
  display: flex;
  align-items: center;
}

.header-link {
  padding: 0 14px;
  text-decoration: none;
  padding-top: 1px;
  font-size: 1.02em;
}

a#header-logo {
  margin-right: 30px;
  text-decoration: none;
  white-space: nowrap;
}
@media (max-width: 700px) {
  a#header-logo {
    margin-right: 15px;
  }
}

#header-logo {
  position: relative;
  top: -1px;
}
#header-logo > img {
  height: 33px;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  top: -1px;
}
#header-logo > h1 {
  vertical-align: middle;
  padding-left: 10px;
  font-size: 1.55em;
  margin: 0;
}

#header-logo > h1 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 500;
  color: var(--text-color);
}
