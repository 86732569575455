:root {
  --panel_bg_1: rgba(255, 255, 255, 0.55);
  --panel_bg_2: rgba(0, 0, 0, 0.065);
  --panel-bg-blur: 5px;
}

#more_panel {
  position: relative;
  z-index: 1;
}
#more_panel > * {
  position: relative;
}
#more_panel > #more_panel_background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#more_panel {
  color: var(--text-color);
}

.more_panel_block_title {
  font-size: 1.5em;
  margin-bottom: 7px;
}

/* Grow panel to cover whole height */
.grow-more-panel {
  display: flex;
  flex-direction: column;
}
#more_panel {
  flex-grow: 1;
}
