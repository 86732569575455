/* Style */

:root {
  --max-content-width: 850px;
  --content-side-padding: 20px;
}
@font-face {
  font-family: "Lato";
  font-weight: normal;
  src: local("Lato"), local("Lato Regular"), local("Lato-Regular"),
    url(/Lato-Regular.hash_2d36b1a925432bae7f3c53a340868c6e.ttf) format("truetype");
}
@font-face {
  font-family: "Lato";
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url(/Lato-Light.hash_2fe27d9d10cdfccb1baef28a45d5ba90.ttf) format("truetype");
}
#more_panel,
#footer_container,
#header-container,
#screen-buttons-wrapper,
#fv_left-side {
  font-family: Lato, "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
}

#footer_container {
  font-size: 0.95rem;
  line-height: 1.3rem;
  margin: auto;
  max-width: var(--max-content-width);
  padding-left: var(--content-side-padding);
  padding-right: var(--content-side-padding);
  padding-bottom: 20px;
}

/* Responsive Layout */
#footer_container {
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(1, auto);
  grid-gap: 10px;
}
.footer_section:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}
.footer_section:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
}
.footer_section:nth-child(3) {
  grid-column: 3;
  grid-row: 1;
}
.footer_section:nth-child(4) {
  grid-column: 4;
  grid-row: 1;
}
@media (max-width: 699px) {
  #footer_container {
    justify-content: space-around;
    display: grid;
    grid-row-gap: 8px;
    grid-column-gap: 19px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }
  .footer_section:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .footer_section:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }
  .footer_section:nth-child(3) {
    grid-column: 1;
    grid-row: 2;
  }
  .footer_section:nth-child(4) {
    grid-column: 2;
    grid-row: 2;
  }
}
